<template>
  <div>
    <NavBar></NavBar>
    <div class="container">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <div v-if="hasError">
          <div class=" alert alert-danger  mt-3">
            {{errorMessage}}
          </div>
        </div>
        <div v-if="redeemSuccess">
          <h3 class="text-center">Redeem Success</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebService from "@/webservice";
import AuthHelper from "@/authHelper";
import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import Consts from "@/consts";

export default {
  name: "RedeemSuccess",
  components: {NavBar, Loader},
  data(){
    return {
      redeemSuccess: false,
      jwt: this.$route.query.jwt,
      loading: false,
      hasError: false,
      errorMessage:""
    }
  },
  created() {
    this.loading = true
    if (!this.jwt){
      this.hasError = true
      this.errorMessage = "Error: the link you provided is invalid, can you click again on the link you received on your email"
      this.loading = false
      // empty jwt
      return
    }
    this.redeem()
  },
  methods:{
    redeem(){
      AuthHelper.getCurrentUser(false).then((user) => {
        if (user.anonymous) {
          console.error("user is anonymous")
          this.hasError = true
          this.errorMessage = "Error: the link you provided is invalid, can you click again on the link you received on your email"
          this.loading = false
          return
        }
        WebService.redeemSubscriptions(user.idToken,this.jwt).then(()=>{
          this.loading = false
          this.redeemSuccess = true
          this.$router.push({name: Consts.ROUTE_NAMES.MY_COURSES})
        }).catch((err)=>{
          console.error(err)
          this.hasError = true
          this.errorMessage = "Error: the link you provided is invalid, can you click again on the link you received on your email"
          this.loading = false
        })
      }).catch((err)=>{
        console.error(err)
        this.hasError = true
        this.errorMessage = "Error: the link you provided is invalid, can you click again on the link you received on your email"
        this.loading = false
      })

    }
  }
}
</script>

<style scoped>

</style>